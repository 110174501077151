import React, { useEffect, useState } from 'react';
import * as selectors from 'app/selectors/customer';
import { selectCustomer } from 'app/selectors/customer';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router/immutable';
import { AvailableProducts, MultiMonthPlanMapping } from 'app/constants/Products';
import SubscriptionWidget from './SubscriptionWidget';
import { Formik } from 'formik';
import { Button, Card, PageWrapper } from 'mui';
import PageHeader from 'app/components/common/PageHeader';
import GenericBYOL from 'app/components/customer/steps/Payment/Generic/GenericBYOL';
import GenericSelfPay from 'app/components/customer/steps/Payment/Generic/GenericSelfPay';
import axios from 'axios';
import { getCSRF } from 'app/api';
import GenericDueToday from 'app/components/customer/steps/Payment/Generic/GenericDueToday';
import { apiRequestUserCommand } from 'app/actions/customer';
import updateSubscriptionSteps from 'app/utils/updateSubscriptionSteps';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import { Product, ProductIntakeImm } from 'app/types/admin/customerUser';
import cx from 'classnames';
import { ImmutableMap } from 'app/types/admin';

const GenericUpdateSubscription = () => {
  const dispatch = useAppDispatch();
  const [byolFormSubmitted, setByolFormSubmitted] = useState(false);
  const [byolParams, setByolParams] = useState();

  const { search } = useLocation();
  const query_params = new URLSearchParams(search);

  const currentProductName = query_params.get('product') as AvailableProducts;
  const customer = useAppSelector(selectCustomer);
  const productsImm = useAppSelector(selectors.selectCustomerProducts);
  const product = productsImm.get(currentProductName) as ImmutableMap<Product>;

  if (!product) throw new Error('No product');

  const intakes = product.get('intakes');
  const intake = intakes.get(intakes.size - 1) as ProductIntakeImm;

  const checkoutHelper = createCheckoutHelper(intake, customer, product, false, query_params);

  const [currentStep, setCurrentStep] = useState(checkoutHelper.firstStep());

  useEffect(() => {
    if (currentStep === updateSubscriptionSteps.productStep) {
      checkoutHelper.trackRecommendationGenerated();
    }
  }, [currentStep]);

  const uploadLabResults = async (params) => {
    if (params === undefined) return;

    await axios.post(
      '/api/commands',
      {
        ...params,
        user_id: customer.get('id'),
        intake_name: intake.get('name'),
        photo_purpose: `${currentProductName}_onboarding_lab`,
        cmdType: 'upload_own_lab_results',
        type: 'upload_own_lab_results',
      },
      { headers: { 'X-CSRF-Token': getCSRF() } },
    );
  };

  const onSubmit = (params: any) => {
    const action = apiRequestUserCommand({
      cmdType: 'update_subscription',
      params: {
        ...params,
        intake_name: intake.get('name'),
      },
      context: {
        onSuccessActionCreator: () => {
          uploadLabResults(byolParams);

          return push(checkoutHelper.redirectTo(params));
        },
      },
    });

    dispatch(action);
  };

  const initialValues = {
    selfPay: false,
    refundPolicy: false,
    include_supplement:
      !checkoutHelper.supplementsOnAnotherIntake() && product.get('opt_in_choice')?.get('include_supplement'),
    include_dermastamp: product.get('opt_in_choice')?.get('include_dermastamp'),
    multimonth_plan: product.get('opt_in_choice')?.get('multimonth_plan') || 1,
    use_own_lab: product.get('use_own_lab'),
    own_lab_file_uploaded: customer.get('own_lab_file_uploaded'),
    ...checkoutHelper.productSpecificInitialValues(),
  };

  const productStep = (values) => (
    <>
      <PageHeader title="Choose Your Product" />
      <div
        className={cx({
          'flex flex-col gap-y-6 lg:gap-y-8': true,
          'mb-60': checkoutHelper.recommendationSystemEnabled() && checkoutHelper.subProductName(values),
        })}
      >
        {checkoutHelper.renderProductStep(false, () => setCurrentStep(checkoutHelper.secondStep()))}
      </div>
    </>
  );

  const multimonthStep = () => (
    <>
      <PageHeader title="Choose Your Plan" />
      {checkoutHelper.renderMultimonthStep(() => setCurrentStep(updateSubscriptionSteps.productStep), false)}
      <Button
        onClick={() => {
          setCurrentStep(updateSubscriptionSteps.paymentStep);
        }}
      >
        Continue
      </Button>
    </>
  );

  const checkoutStep = (handleSubmit, dirty, values) => {
    const priceCalculator = checkoutHelper.priceCalculator(values);

    return (
      <>
        <div className="px-4">
          <h3 className="mb-3">Confirm Plan Update</h3>
          {checkoutHelper.checkoutBreadcrumbs(
            dispatch,
            () => setCurrentStep(updateSubscriptionSteps.planStep),
            () => setCurrentStep(updateSubscriptionSteps.productStep),
          )}
        </div>
        <Card>
          <Card.Body>
            <SubscriptionWidget
              currentProductName={currentProductName}
              priceCalculator={priceCalculator}
              multimonthPeriod={MultiMonthPlanMapping[values.multimonth_plan]}
              multimonthPlan={values.multimonth_plan}
              isOnboarding={false}
              supplementsOnAnotherIntake={checkoutHelper.supplementsOnAnotherIntake()}
              activeProductWithSupplementName={checkoutHelper.activeProductWithSupplementName()}
              checkoutHelper={checkoutHelper}
              productChanged={checkoutHelper.productChanged(values)}
              subProductName={checkoutHelper.subProductName(values)}
            />
            {checkoutHelper.showLabAndDueTodaySection(values) ? (
              <>
                <GenericBYOL
                  kingV2OralTrt={checkoutHelper.isKingV2OralTrt(values.selected_king_v2_product)}
                  priceCalculator={priceCalculator}
                  intake={intake}
                  byolParams={byolParams}
                  setByolParams={setByolParams}
                  setByolFormSubmitted={setByolFormSubmitted}
                  byolFormSubmitted={byolFormSubmitted}
                />
                <GenericDueToday
                  multimonthPeriod={values.multimonth_plan}
                  multimonthPlan={values.multimonth_plan}
                  starterPack={checkoutHelper.productSpecificInitialValues()['starter_pack']}
                  isOnboarding={false}
                  priceCalculator={priceCalculator}
                  showLabKit={checkoutHelper.showLabAndDueTodaySection(values)}
                  intake={intake}
                />
              </>
            ) : (
              <GenericDueToday
                multimonthPeriod={values.multimonth_plan}
                multimonthPlan={values.multimonth_plan}
                starterPack={checkoutHelper.productSpecificInitialValues()['starter_pack']}
                isOnboarding={false}
                priceCalculator={priceCalculator}
                showLabKit={checkoutHelper.showLabAndDueTodaySection(values)}
                intake={intake}
              />
            )}
            <GenericSelfPay />
            <Button
              type="button"
              disabled={
                !dirty ||
                !values.selfPay ||
                checkoutHelper.specialDisabledConditions(values, { byolFormSubmitted: byolFormSubmitted })
              }
              onClick={handleSubmit}
            >
              Update Subscription
            </Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  const renderCurrentStep = (handleSubmit, dirty, values) => {
    switch (currentStep) {
      case updateSubscriptionSteps.productStep:
        return productStep(values);
      case updateSubscriptionSteps.planStep:
        return multimonthStep();
      case updateSubscriptionSteps.paymentStep:
        return checkoutStep(handleSubmit, dirty, values);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ values, dirty, handleSubmit }) => (
        <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
          {renderCurrentStep(handleSubmit, dirty, values)}
        </PageWrapper>
      )}
    </Formik>
  );
};

export default GenericUpdateSubscription;
